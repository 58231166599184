'use client';

import React, { useContext } from 'react';
import { ShapeContext } from '@/app/providers';
import { redirect } from 'next/navigation';
import Spinner, { SpinnerWrapped } from '@/components/spinner/spinner';

function Page(props) {
    const { shapes, shape } = useContext(ShapeContext);

    if (!shapes) {
        return <SpinnerWrapped />;
    }

    if (Object.keys(shapes).length === 0) {
        redirect('/create');
    }

    if (!shape) {
        return <SpinnerWrapped />;
    }

    redirect(`/${shape.username}`);
}

export default Page;
