import styles from './spinner.module.scss';

function Spinner(props) {
    return (
        <div className={styles.ldsEllipsis}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export function SpinnerWrapped({ content = null }) {
    return (
        <div className={styles.wrapper}>
            <Spinner />
            {content && <span>{content}</span>}
        </div>
    );
}

export default Spinner;
